/* SignupModal.css */
.signup-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000 !important;
  backdrop-filter: blur(5px);
  padding: 1rem;
  /* padding-top: calc(80px + 1rem); */
  overflow: hidden;
  /* Empêche le scroll sur l'overlay */
}

.signup-modal .modal-content.signup-content {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  position: relative;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  animation: modalAppear 0.3s ease-out;
  max-height: calc(100vh - 100px);
  /* Hauteur maximale avec marge */
  overflow-y: auto;
  /* Permet le défilement si le contenu est trop long */
  scrollbar-width: thin;
  /* Pour Firefox */
  scrollbar-color: #bd193b #f0f0f0;
  /* Pour Firefox */
}

@keyframes modalAppear {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.signup-modal .modal-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  color: #666;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
  padding: 10px;
  z-index: 2001;
  /* S'assurer que le bouton est toujours cliquable */
}

.signup-modal .modal-close:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #bd193b;
  /* transform: rotate(90deg); */
  transform: scale(1.1);
}

.signup-modal .modal-logo {
  text-align: center;
  margin-bottom: 1rem;
}

.signup-modal .modal-logo img {
  height: 50px;
  width: auto;
  margin: auto;
}

.signup-modal .signup-form {
  padding-right: 0.5rem;
  /* Petit espace pour éviter que le contenu touche la scrollbar */
}

.signup-modal .modal-title {
  text-align: center;
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.signup-modal .signup-form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.signup-modal .form-row {
  display: flex;
  gap: 0.75rem;
  width: 100%;
}

.signup-modal .form-group {
  flex: 1;
  position: relative;
}

.signup-modal .form-group input {
  width: 100%;
  padding: 0.7rem 0.9rem;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  background: #f8f9fa;
}

.signup-modal .form-group input:focus {
  border-color: #bd193b;
  background: white;
  outline: none;
  box-shadow: 0 0 0 3px rgba(189, 25, 59, 0.1);
}

.signup-modal .form-group input::placeholder {
  color: #999;
  font-size: 0.9rem;
}

.signup-modal .error-message {
  color: #dc3545;
  font-size: 0.8rem;
  margin-top: 0.25rem;
  display: block;
  padding-left: 0.5rem;
}

/* Ajoutez ces styles à votre SignupModal.css */

.signup-modal .loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  border-radius: 20px;
}

.signup-modal .loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #bd193b;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.signup-modal .global-error {
  text-align: center;
  margin-bottom: 1rem;
  padding: 0.75rem;
  background-color: rgba(220, 53, 69, 0.1);
  border-radius: 8px;
  color: #dc3545;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.signup-modal .signup-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.signup-modal .form-group input:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
}

.signup-modal .link-button:disabled {
  color: #999;
  cursor: not-allowed;
}

.signup-modal .modal-close:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.signup-modal .my-signup-button {
  background-color: #bd193b;
  color: white;
  padding: 0.8rem;
  border: none;
  border-radius: 10px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0.5rem 0 0 0 !important;
  box-shadow: 0 4px 6px rgba(189, 25, 59, 0.1);
}

/* Styles de la barre de défilement pour Chrome/Safari/Edge */
.signup-modal .modal-content.signup-content::-webkit-scrollbar {
  width: 8px;
}

.signup-modal .modal-content.signup-content::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 10px;
  margin: 1rem 0;
}

.signup-modal .modal-content.signup-content::-webkit-scrollbar-thumb {
  background-color: #bd193b;
  border-radius: 10px;
  border: 2px solid #f0f0f0;
}

.signup-modal .modal-content.signup-content::-webkit-scrollbar-thumb:hover {
  background-color: #a11532;
}

.signup-modal .signup-button:hover {
  background-color: #a11532;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(189, 25, 59, 0.2);
}

.signup-modal .login-link {
  text-align: center;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #666;
}

.signup-modal .link-button {
  background: none;
  border: none;
  padding: 0.25rem 0.5rem;
  margin-left: 0.25rem;
  color: #bd193b;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 4px;
}

.signup-modal .link-button:hover {
  color: #a11532;
  text-decoration: underline;
  background-color: rgba(189, 25, 59, 0.05);
}

.signup-modal .link-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(189, 25, 59, 0.2);
}

/* Responsive design */
@media (max-width: 576px) {
  .signup-modal .modal-overlay {
    padding-top: 70px;
    padding: 0.5rem;
    padding-top: 70px;
  }

  .signup-modal .modal-content.signup-content {
    max-height: calc(100vh - 90px);
    margin: 0.5rem;
    padding: 1.5rem;
  }

  .signup-modal .form-row {
    flex-direction: column;
    gap: 0.75rem;
  }

  .signup-modal .modal-logo img {
    height: 45px;
  }

  .signup-modal .modal-title {
    font-size: 1.3rem;
    margin-bottom: 1.25rem;
  }

  .signup-modal .form-group input {
    padding: 0.6rem 0.8rem;
  }

  .signup-modal .my-signup-button {
    margin: 0.5rem 0 0 0 !important;
  }
}

/* Animation de transition pour les inputs */
@keyframes inputFocus {
  0% {
    background-color: #f8f9fa;
  }

  100% {
    background-color: white;
  }
}

.signup-modal .form-group input:focus {
  animation: inputFocus 0.3s ease forwards;
}