 .leads {
 	padding: 20px;
 	background-color: #f9f9f9;
 	font-family: Arial, sans-serif;
 }

 .leads .action-section {
 	display: flex;
 	justify-content: space-between;
 	align-items: center;
 	margin-bottom: 20px;
 }

 .leads .table-section {
 	margin-bottom: 20px;
 	overflow-x: auto;
 }

 .leads .pagination-section {
 	display: flex;
 	justify-content: flex-start;
 	align-items: center;
 }