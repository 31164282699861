.tiles-container {
    padding: 2.4rem 2rem;
    max-width: 1400px; /* Réduit de 75% par rapport à 1400px */
    margin: 0 auto;
    min-height: calc(100vh - 200px);
  }
  
  .tiles-header {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .tiles-header h1 {
    font-size: 2.3rem;
    color: #1a1a1a;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  
  .tiles-header h3 {
    font-size: 2rem;
    color: #000;
    margin-top: 1rem;
  }

  .tiles-header p {
    color: #bd193b;
  }
  
  .tiles-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    margin: 0 auto;
  }
  
  .tile {
    width: 100%;
    max-width: 300px; /* Taille réduite */
    background: white;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    border: 1px solid #eee;
    margin: 0 auto;
  }
  
  .tile:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-color: #bd193b;
  }
  
  .tile-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background: #bd193b;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 2;
  }
  
  .tile-image {
    width: 100%;
    height: 150px; /* Hauteur réduite */
    overflow: hidden;
  }
  
  .tile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .tile:hover .tile-image img {
    transform: scale(1.05);
  }
  
  .tile-content {
    padding: 1.25rem;
  }
  
  .tile-content h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 0.5rem;
  }
  
  .tile-content p {
    font-size: 0.9rem;
    color: #666;
    margin: 0;
  }
  
  @media (max-width: 1024px) {
    .tiles-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 1.25rem;
    }
  }
  
  @media (max-width: 768px) {
    .tiles-container {
      padding: 2rem 1rem;
    }
    
    .tiles-grid {
      grid-template-columns: 1fr;
      max-width: 300px;
      margin: 0 auto;
    }
    
    .tiles-header h1 {
      font-size: 1.75rem;
    }
  }