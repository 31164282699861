.leads .id-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.leads .td-link a {
    color: #1E88E5;
    text-decoration: none;
}

.leads .td-link a:hover {
    text-decoration: underline;
}

.leads .status-green {
    color: #4CAF50;
    font-weight: bold;
}

.leads .status-red {
    color: #F44336;
    font-weight: bold;
}

.leads .status-blue {
    color: #2196F3;
    font-weight: bold;
}

.leads .status-default {
    color: #333;
}

.leads .date-green {
    color: #4CAF50;
}

.leads .comment-link,
.assign-link {
    color: #1E88E5;
    text-decoration: none;
}

.leads .comment-link:hover,
.assign-link:hover {
    text-decoration: underline;
}

.leads .td-pro {
    text-align: center;
}

.leads .pro-icon {
    width: 20px;
    height: 20px;
    color: #333;
}

.leads .td-actions {
    text-align: center;
}

.leads .btn-action {
    background: none;
    border: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leads .btn-action svg {
    width: 18px;
    height: 18px;
    color: #555;
}

.leads tr.selected {
    background-color: #E3F2FD !important;
}