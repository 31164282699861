.leads .leads-filter {
    display: flex;
    align-items: center;
    gap: 20px;
}

.leads .leads-filter form {
    display: flex;
    align-items: center;
    gap: 10px;
}

.leads .search-input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.leads .leads-filter input {
    padding: 8px 12px;
    padding-right: 40px;
    /* Espace pour l'icône de recherche */
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 300px;
    font-size: 14px;
}

.leads .search-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leads .filter-button {
    background: none;
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leads .search-button svg,
.filter-button svg {
    width: 20px;
    height: 20px;
    color: #555;
}