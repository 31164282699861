.affiliation-container {
    max-width: 1200px;
    margin: 0 auto;
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  /* Header Styles */
  .affiliation-profile .profile-header {
    position: relative;
    display: flex !important;
    justify-content: start;
    padding: 1rem;
    gap: 50px;
  }
  
  
  
 
  
  @media (max-width: 768px) {
    .affiliation-profile .profile-header-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0 1rem;
      width: 100%!important;
    }

    .affiliation-profile .profile-header {
      position: relative;
      display: flex !important;
      flex-wrap: wrap;
      align-items: center;
      justify-content: start;
      padding: 1rem;
      gap: 50px;
    }
  }