/* BeauticianAgenda.css */
.agenda-container {
  margin: 0 auto;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Header Styles */
.agenda-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 2rem; */
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--gray-200);
}

.date-navigation {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav-button {
  padding: 0.5rem;
  background: none;
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  color: var(--gray-700);
  cursor: pointer;
  transition: all 0.2s;
}

.nav-button:hover {
  background: var(--gray-50);
  border-color: var(--gray-300);
}

.current-date {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: var(--gray-50);
  border-radius: 8px;
  color: var(--gray-900);
  font-weight: 500;
}

.new-appointment-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: var(--primary);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.new-appointment-button:hover {
  background: var(--primary-hover);
}

/* Agenda View */
.agenda-view {
  display: flex;
  gap: 1rem;
  height: 80vh;
  overflow-y: auto;
}

.time-sidebar {
  width: 60px;
  flex-shrink: 0;
}

.time-slot {
  height: 100px;
  display: flex;
  align-items: start;
  padding-top: 0.5rem;
  color: var(--gray-500);
  font-size: 0.875rem;
}

.appointments-container {
  flex: 1;
  position: relative;
}

.time-grid {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.grid-slot {
  height: 100px;
  border-bottom: 1px solid var(--gray-100);
}

.appointments-layer {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* calendar */
.calendar-container {
  width: 100%;
  max-width: 100%; /* Ajuste la largeur au conteneur modal */
}

.react-calendar {
  width: 100% !important; /* Empêche le calendrier de dépasser */
  max-width: 100% !important; /* Assure que le calendrier ne dépasse pas la largeur du conteneur */
  border-radius: 8px; /* Optionnel : ajout d'une bordure arrondie pour une meilleure apparence */
}

/* Backdrop principal */
.main-backdrop {
  background-color: rgba(0, 0, 0, 0.5); /* Couleur semi-transparente */
  z-index: 1049; /* Z-index inférieur à celui de la sous-modal */
}

/* Backdrop secondaire */
.secondary-backdrop {
  background-color: rgba(0, 0, 0, 0.8); /* Couleur plus sombre */
  z-index: 1050; /* Z-index supérieur à celui du backdrop principal */
}


/* Appointment Card */
/*.appointment-card {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 0.5rem;
    background: white;
    border-left: 4px solid;
    border-radius: 6px;
    padding: 0.75rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .appointment-card:hover {
    transform: scale(1.02);
  }
  
  .appointment-card.confirmed {
    border-left-color: #059669;
  }
  
  .appointment-card.pending {
    border-left-color: #eab308;
  }*/

.appointment-time {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--gray-900);
  margin-bottom: 0.5rem;
}

.appointment-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.appointment-client,
.appointment-service,
.appointment-duration {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: var(--gray-700);
}

.appointment-more {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.25rem;
  background: none;
  border: none;
  color: var(--gray-500);
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s;
}

.appointment-more:hover {
  background: var(--gray-100);
  color: var(--gray-900);
}

.appointment-card-btn {
  padding: 8px 16px; /* Espacement interne */
  font-weight: 500; /* Épaisseur du texte */
  color: white; /* Texte blanc */
  border: none; /* Aucun bord */
  border-radius: 4px; /* Coins arrondis */
  cursor: pointer; /* Curseur pointeur */
  transition: background-color 0.3s ease; /* Transition fluide */
}

.appointment-card-btn.confirm {
  background-color: #16a34a; /* Vert (bg-green-600) */
}

.appointment-card-btn.confirm:hover {
  background-color: #15803d; /* Vert plus foncé (bg-green-700) */
}

.appointment-card-btn.reject {
  background-color: #dc2626; /* Rouge (bg-red-600) */
}

.appointment-card-btn.reject:hover {
  background-color: #b91c1c; /* Rouge plus foncé (bg-red-700) */
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.appointment-modal {
  background: white;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  padding: 1.5rem;
  border-bottom: 1px solid var(--gray-200);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.25rem;
  color: var(--gray-900);
}

.close-button {
  background: none;
  border: none;
  color: var(--gray-500);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 6px;
  transition: all 0.2s;
}

.close-button:hover {
  background: var(--gray-100);
  color: var(--gray-900);
}

.modal-content {
  padding: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--gray-700);
  font-weight: 500;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--gray-300);
  border-radius: 6px;
  font-size: 0.875rem;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.modal-footer {
  padding: 1.5rem;
  border-top: 1px solid var(--gray-200);
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.cancel-button,
.confirmation-button {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.cancel-button {
  background: white;
  border: 1px solid var(--gray-300);
  color: var(--gray-700);
}

.cancel-button:hover {
  background: var(--gray-50);
  border-color: var(--gray-400);
}

.confirmation-button {
  background: var(--primary);
  border: none;
  color: white;
}

.confirmation-button:hover {
  background: var(--primary-hover);
}

/* Responsive Design */
@media (max-width: 768px) {
  .agenda-container {
    padding: 0.5rem;
  }

  .agenda-header {
    flex-direction: column;
    gap: 1rem;
  }

  .date-navigation {
    width: 100%;
    justify-content: space-between;
  }

  .new-appointment-button {
    width: 100%;
    justify-content: center;
  }

  .form-row {
    grid-template-columns: 1fr;
  }
}
