.profile-container {
    max-width: 1200px;
    margin: 0 auto;
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  /* Header Styles */
  .beautician-profile .profile-header {
    position: relative;
    display: flex !important;
    justify-content: start;
    padding: 1rem;
    gap: 50px;
  }
  
  .profile-cover {
    height: 200px;
    background-color: var(--gray-100);
    position: relative;
  }
  
  .edit-cover-btn {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .edit-cover-btn:hover {
    background: white;
  }
  
  .profile-header-content {
    padding: 0 2rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .profile-avatar-wrapper {
    margin-right: 2rem;
  }
  
  .profile-avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid white;
    position: relative;
    background: white;
  }
  
  .avatar-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .edit-avatar-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    background: white;
    border: 2px solid var(--gray-100);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .edit-avatar-btn:hover {
    background: var(--gray-50);
  }
  
  .b-profile-information {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  }
  
  .profile-titles h1 {
    margin: 0;
    font-size: 1.5rem;
    color: var(--gray-900);
  }
  
  .profile-titles p {
    margin: 0.25rem 0 0 0;
    color: var(--gray-600);
  }
  
  .edit-profile-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: white;
    border: 1px solid var(--gray-200);
    border-radius: 8px;
    color: var(--gray-700);
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .edit-profile-btn:hover {
    background: var(--gray-50);
    border-color: var(--gray-300);
  }
  
  /* Content Styles */
  .profile-content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    padding: 2rem;
  }
  
  .profile-main {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .profile-section-portal {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    border: 1px solid var(--gray-200);
  }
  
  .profile-section-portal h2 {
    margin: 0 0 1rem 0;
    font-size: 1.25rem;
    color: var(--gray-900);
  }
  
  .bio {
    color: var(--gray-700);
    line-height: 1.6;
    margin: 0;
  }
  
  .specialities-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .speciality-tag {
    padding: 0.5rem 1rem;
    background: var(--primary-light);
    color: var(--primary);
    border-radius: 20px;
    font-size: 0.875rem;
  }
  
  .schedule-grid {
    display: grid;
    gap: 0.75rem;
  }
  
  .schedule-row {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--gray-100);
  }
  
  .schedule-row:last-child {
    border-bottom: none;
  }
  
  .day {
    color: var(--gray-700);
  }
  
  .hours {
    color: var(--gray-900);
    font-weight: 500;
  }
  
  /* Sidebar Styles */
  .profile-sidebar {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .contact-info,
  .availability-card {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    border: 1px solid var(--gray-200);
  }
  
  .contact-info h2,
  .availability-card h2 {
    margin: 0 0 1rem 0;
    font-size: 1.25rem;
    color: var(--gray-900);
  }
  
  .contact-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .contact-item {
    display: grid;
    align-items: center;
    gap: 0.75rem;
    color: var(--gray-700);
    grid-template-columns: 1fr 6fr;
  }
  
  .availability-status {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem;
    border-radius: 8px;
    /* margin-bottom: 1rem; */
  }
  
  .availability-status.available {
    background: #ecfdf5;
    color: #059669;
  }
  
  .next-availability {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: var(--gray-700);
  }
  
  .next-availability strong {
    color: var(--gray-900);
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .profile-content {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .beautician-profile {
      display: none;
    }
    .beautician-profile .profile-header-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0 1rem;
      width: 100%!important;
    }

    .beautician-profile .profile-header {
      position: relative;
      display: flex !important;
      flex-wrap: wrap;
      align-items: center;
      justify-content: start;
      padding: 1rem;
      gap: 50px;
    }
  
    .profile-avatar-wrapper {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  
    .b-profile-information {
      flex-direction: column;
      gap: 1rem;
    }
  
    .profile-content {
      padding: 1rem;
    }
  
    .contact-info,
    .availability-card {
      padding: 1rem;
    }
  }