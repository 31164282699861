.leads .leads-table-container {
    width: 100%;
    overflow-x: auto;
}

.leads .leads-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
}

.leads .leads-table th {
    background-color: #C1272D;
    color: white;
    padding: 12px 8px;
    text-align: left;
    position: sticky;
    border-left: 3px solid #ffffff;
    top: 0;
}

.leads .leads-table th:first-child {
    border-top-left-radius: 4px;
}

.leads .leads-table th:last-child {
    border-top-right-radius: 4px;
}

.leads .leads-table td {
    padding: 12px 8px;
    border-bottom: 1px solid #eee;
}

.leads .leads-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.leads .leads-table tbody tr:hover {
    background-color: #f1f1f1;
}

.leads .th-checkbox,
.td-checkbox {
    width: 50px;
    text-align: center;
}