.tarif-card {
    background: white;
    border-radius: 8px;
    padding: 0 1.5rem 1.5rem;
    width: 350px;
    /* height: 700px; */
    margin: 0 auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
    border-top: 6px solid #bd193b;
    /* Ajout de la ligne épaisse en haut */
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.tarif-card .price {
    margin: 1rem -3rem;
    /* Marge négative pour compenser le padding de la carte */
    padding: 0 1.5rem 1rem;
    /* Padding horizontal pour maintenir l'alignement */
    border-bottom: 1px solid #e0e0e0;
}
.tarif-card .priceaddon{
    font-size: 0.8rem;
}
.tarif-card .description{
    font-size: 0.9rem ;
}

.tarif-illustration {
    margin-top: auto;
    width: 100%;
    height: 175px;
    /* Hauteur fixe pour l'image */
    overflow: hidden;
    border-radius: 4px;
}

.tarif-illustration img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

/* Style pour la carte désactivée */
.tarif-card.disabled {
    background: #f5f5f5;
}

/* Style pour le filigrane */
.watermark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    font-size: 2rem;
    color: rgba(189, 25, 59, 0.1);
    white-space: nowrap;
    font-weight: bold;
    pointer-events: none;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    z-index: 1;
}

.tarif-card h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin: 1.5rem 0 0.5rem 0;
}

.tarif-card .price {
    margin: 0;
    padding-bottom: 0;
    border-bottom: 1px solid #e0e0e0;
    /* Trait sous le prix */
}

.tarif-card .amount {
    font-size: 2rem;
    font-weight: bold;
    color: #bd193b;
}

.tarif-card .period {
    font-size: 1rem;
    color: #bd193b;
}

.features-list {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    /* Permet à la liste de prendre l'espace disponible */
}

.features-list li {
    padding: 0.2rem 0;
    color: #333;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 8px;
}

.features-list li:before {
    content: "•";
    color: #bd193b;
    font-size: 1.2rem;
}

/* Style pour la version "disabled" */
.tarif-card.disabled {
    background: #f5f5f5;
}

.tarif-card.disabled .features-list li {
    color: #666;
}

.tarif-card .coming-soon {
    font-size: 1.2rem;
    font-weight: 600;
    color: #666;
}


@media (max-width: 768px) {
    .tarif-card {
        width: 100%;
        max-width: 380px;
        margin-bottom: 2rem;
    }

    .tarif-card h2 {
        font-size: 1.8rem;
    }

    .tarif-card .amount {
        font-size: 2rem;
    }
}