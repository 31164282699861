/** hero **/

.title_bg {
    width: 100%;
}

/** end hero **/


/** title **/
.title-txt {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0 60px 0;
    margin: 20px 0 20px 0;
}

.title-fonctionnalites {
    text-align: center;
    font-size: 45px;
}

.f-star {
    margin: 20px 0px 0 5px;
    width: 20px;
    height: 20px;
}

/** titles end **/



/** fonctionnalites content**/

.fonctionnalites-content {
    width: 100%;
    padding: 0 0 50px 0;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fonctionnalite-wrapper1,
.fonctionnalite-wrapper2 {
    width: 70vw;
    padding: 40px 0 40px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.textzone {
    width: 30vw;

}

.fonctionnalites-content .textzone h2 {
    width: 28vw;
    font-size: 35px;
    padding: 20px 0 20px 0;
}

.fonctionnalites-content .textzone h4 {
    text-transform: uppercase;
    font-size: 18px;
}

.fonctionnalites-content .textzone p {
    width: 28vw;
    font-size: 18px;
    text-align: justify;
}

.imgzone {
    width: 30vw;
    padding: 0 10px 0 10px;
    height: auto;
}

/** fonctionnalite content end**/


/** Communauty**/

.communication-wrapper {
    width: 60vw;
    padding: 40px 0 40px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.textzone-communication {
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.com-btn {
    display: flex;
    justify-content: center;
    background-color: rgb(41, 41, 41);
    width: auto;
    height: auto;
    padding: 10px 40px 10px 40px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    color: rgb(244, 250, 255);
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
}

.textzone-communication h4 {
    margin: 60px 0 0 0;
    text-align: center;
    font-size: 30px;
    font-weight: normal;
}

.commu-img {
    width: 30vh;
    height: 30vh;
}

/** Communauty end**/

/** Line**/

.ligne {
    display: flex;
    align-items: center;
}

.line-end {
    width: 20vw;
    border-bottom: solid 3px #870022;
    margin: 50px 0 50px 0;
    opacity: 0.8;
}

/** Line end**/

/** tarifs**/
.tarif-wrapper {
    width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.text-tarifs {
    width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 3px solid #ffcfd8a2;
    border-radius: 30px;

}


.text-tarifs h4 {
    padding: 40px 0 0 0;
}

.text-tarifs h3 {
    padding: 20px 0 20px 0;
    font-weight: bolder;
    font-size: 80px;
    text-align: center;
    color: #870022;
}

.tarif-img {
    width: 30vh;
    height: 30vh;
    position: absolute;
    margin: -300px 600px 0 0;
}

@media screen and (min-width: 1600px) {
    .tarif-img {
        margin: -300px 700px 0 0;
    }
}

.suggestions {
    margin: 40px 0 40px 0;
    height: auto;

}

.suggestions p {
    text-align: center;
    padding: 0;
    margin: -5px 0 -5px 0;
    font-size: 16px;
}



/** tarifs end**/



/**Resposivity**/


@media screen and (max-width: 990px) {
    .title_bg {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .textzone,
    .imgzone {
        width: 40vw;
    }

    .fonctionnalites-content .textzone h2 {
        font-size: 22px;
    }

    .fonctionnalites-content .textzone h4,
    .fonctionnalites-content .textzone p {
        text-align: normal;
        font-size: 15px;
    }

    .tarif-wrapper {
        width: 80vw;
        display: flex;
        align-items: center;
    }

    .text-tarifs {
        width: 60vw;
        display: flex;
        flex-direction: column;
    }


    .communication-wrapper {
        width: 80vw;
        flex-direction: column;
    }

    .textzone-communaute {
        flex-direction: column-reverse;
        width: 60vh;
        height: max-content;
    }

    .com-btn {
        margin: 20px 0 20px 0;
    }

    .commu-img {
        width: 30vh;
        height: 30vh;
        opacity: 0.7;
    }

    .textzone-communication h4 {
        font-size: 25px;
        margin-bottom: 20px;
    }

    .tarif-wrapper {
        width: 50vw;
    }

    .tarif-img {
        width: 200px;
        height: 200px;
        transition: 0.5s;
        margin: -200px 600px 0 0;
    }
}






@media screen and (max-width: 769px) {

    .title_bg {
        width: 100%;
    }

    .title-fonctionnalites {
        font-size: 38px;
    }

    .fonctionnalite-wrapper1 {
        flex-direction: column-reverse;
    }

    .fonctionnalite-wrapper2 {
        flex-direction: column;
    }

    .imgzone {
        width: 60vw;
    }

    .textzone {
        width: 70vw;
        transition: 0.5s;
    }

    .fonctionnalites-content .textzone h2,
    .fonctionnalites-content .textzone h4,
    .fonctionnalites-content .textzone p {
        width: 60vw;
    }


    .line-end {
        width: 30vw;
        opacity: 0.8;
        margin: -20px 0 20px 0;

    }


    .textzone-communication {
        width: 60vw;
    }

    .textzone-communication h4 {
        font-size: 20px;
    }


    .tarif-wrapper {
        width: 80vw;
        margin-top: 80px;
    }

    .text-tarifs {
        width: 80vw;
    }

    .tarif-img {
        width: 200px;
        height: 200px;
        transition: 0.5s;
        margin: -100px 400px 0 0;
    }

    .com-btn {
        margin-top: 100px;
    }

}







@media screen and (max-width: 481px) {
    .title_bg {
        width: 100%;
    }

    .title-txt {
        padding: 20px 0 0 0;
        margin: 0;
    }

    .f-star {
        scale: 0.8;
        transition: 0.1s;
    }

    .title-fonctionnalites {
        font-size: 20px;
        padding: 0;
        margin: 0;
    }

    .fonctionnalite-wrapper1,
    .fonctionnalite-wrapper2 {
        width: 80vw;
    }

    .fonctionnalite-wrapper1 {
        flex-direction: column-reverse;
    }

    .fonctionnalite-wrapper2 {
        flex-direction: column;
    }


    .textzone,
    .imgzone {
        width: 70vw;
    }

    .fonctionnalites-content .textzone h2,
    .fonctionnalites-content .textzone h4,
    .fonctionnalites-content .textzone p {
        width: 70vw;
    }

    .textzone-communaute {
        width: 70vw;
    }

    .textzone-communaute h4 {
        font-size: 25px;
    }

    .com-btn {
        margin-top: 20px;
    }

    .tarif-wrapper {
        width: 60vw;
    }

    .tarif-img {
        width: 150px;
        height: 150px;
        margin: -550px 0 0 0;
        padding: 0;
    }
}