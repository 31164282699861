.beautician-list-container {
  padding: 2rem 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Conteneur du carousel */
.beauticians-carousel {
  position: relative;
  padding: 0 3rem;
}

/* Grille des cartes */
.beautician-grid {
  /* display: grid; */
  /* grid-template-columns: repeat(5, 1fr); */
  /* gap: 1.5rem; */
  padding: 0 .5rem;
  @apply grid gap-[0.5rem] sm:gap-[1rem] lg:gap-[1rem];
}

.beautician-grid-item {
  width: 100%;
  height: 100%;
  display: flex;
}


/* Boutons de navigation */
.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  color: #868e96;
  z-index: 1;
}

.carousel-button:hover:not(:disabled) {
  background: #bd193b;
  color: white;
  box-shadow: 0 4px 15px rgba(189, 25, 59, 0.3);
}

.carousel-button:disabled {
  background: #f5f5f5;
  color: #ccc;
  cursor: not-allowed;
  box-shadow: none;
}

.carousel-button.prev {
  left: 0;
}

.carousel-button.next {
  right: 0;
}

/* Points de navigation */
.carousel-dots {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.carousel-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e9ecef;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.carousel-dot.active {
  background: #bd193b;
  transform: scale(1.2);
}

/* Media Queries */
@media (max-width: 1200px) {
  .beautician-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .beautician-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .beauticians-carousel {
    padding: 0 2rem;
  }

  .beautician-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .carousel-button {
    width: 35px;
    height: 35px;
  }

  .beautician-grid-item {
    min-width: calc(50% - 0.5rem); /* Pour s'assurer que deux cartes tiennent sur une ligne */
  }
}

@media (max-width: 576px) {
  .beautician-list-container {
    padding: 1rem 0;
  }

  .beauticians-carousel {
    padding: 0 1.5rem;
  }

  .beautician-grid {
    grid-template-columns: repeat(2, 1fr); /* Maintient 2 cartes par ligne */
    gap: 0.75rem; /* Réduit légèrement l'espace entre les cartes */
  }
}

/* Ajustement pour très petits écrans */
@media (max-width: 360px) {
  .beauticians-carousel {
    padding: 0 1rem;
  }

  .beautician-grid {
    gap: 0.5rem; /* Encore moins d'espace pour les très petits écrans */
  }
}