.info-layout {
  max-width: 1300px;
  display: grid;
  grid-template-columns: 20% 20% 25% 30%;
  padding: 10px;
  gap: 12px;
  align-items: start;
  width: 100%;
  box-sizing: border-box;
}

.info-layout > * {
  height: 100%;
}

.info-wrapper {
  background: white;
  padding: 16px;
  max-width: 1300px;
  margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}

/* Sections */
.info-profile,
.info-details,
.info-schedule,
.info-rating {
  box-sizing: border-box;
  width: 100%;
  background: white;
}

/* Profile column */
.info-profile {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

/* Photo de profil */
.info-photo-container {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto 0.5rem;
}

.info-photo-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Info section */
.info-details {
  margin: 0 .5em;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.info-details h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--gray-900);
  margin: 0;
}

.info-location {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--gray-600);
}

.info-specialties {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.info-specialties h2 {
  font-size: 1rem;
  font-weight: 500;
  color: var(--gray-900);
  margin: 0 0 0.25rem 0;
}

.info-select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--gray-200);
  border-radius: 4px;
  background: white;
  color: var(--gray-900);
}

.info-link {
  color: var(--primary);
  font-size: 0.875rem;
  text-decoration: none;
}

/* Schedule */
.info-schedule {
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 10px;
}

.info-schedule h2 {
  font-size: 1rem;
  font-weight: 500;
  color: var(--gray-900);
  margin: 0 0 0.75rem 0;
}

.info-schedule-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
}

.info-schedule-row {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.875rem;
}

.info-day {
  font-weight: 500;
  color: var(--gray-900);
  width: 1.5rem;
}

.info-status {
  color: var(--gray-600);
}

/* Rating section */
.info-rating {
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.info-rating-header {
  margin-bottom: 8px;
}

.info-rating-title {
  font-weight: 500;
  color: var(--gray-900);
  font-size: 1.1rem;
}

.info-rating-container {
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 10px;
}

.info-rating-global {
  background: #c91c3b;
  color: white;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-rating-score {
  font-size: 2.5rem;
  font-weight: bold;
}

.info-rating-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 5px;
}

.info-rating-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
}

.info-rating-item span:last-child {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 0;
}

.info-rating-footer {
  font-size: 0.875rem;
  color: var(--gray-600);
  margin-top: 12px;
}

.info-rating-footer .count{
  color: #c91c3b;
}

/* Modifications des styles existants */
.info-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

/* Conteneur pour les deux premiers boutons */
.info-actions-top {
  display: flex;
  gap: 0.5rem;
  width: 100%;
}

.info-gift-btn, 
.info-booking-btn {
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  flex: 1;
  white-space: nowrap;
}

.info-gift-btn {
  background: white;
  border: 1px solid #c91c3b;
  color: #c91c3b;
}

.info-booking-btn {
  background: #c91c3b;
  border: none;
  color: white;
}


.info-message-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  background: white;
  color: #c91c3b;
  border: 1px solid #c91c3b;
  transition: all 0.3s ease;
}

.info-message-btn:hover {
  background: #fff5f5;
}

/* Media Queries */
@media (max-width: 1200px) {
  .info-gift-btn, 
  .info-booking-btn {
    width: 110px;
    align-self: center;
  }
  .info-layout {
    grid-template-columns: 25% 35% 35%;
    gap: 1rem;
  }
  
  .info-rating {
    grid-column: 1 / -1;
    width: 100%;
    margin-top: 1rem;
  }
}

@media (max-width: 768px) {
  .info-wrapper {
    padding: 0px;
    border: 0;
  }
  
  .info-layout {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .info-photo-container {
    margin: 0 auto;
  }
  
  .info-details,
  .info-schedule {
    text-align: center;
  }
  
  .info-location {
    justify-content: center;
  }
  
  .info-actions {
    flex-direction: column;
  }
  
  .info-schedule-row {
    justify-content: center;
  }

  .info-rating-container {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  
  .info-rating-global {
    padding: 16px;
  }
  
  .info-rating-score {
    font-size: 2rem;
  }

  .info-actions {
    flex-direction: column;
  }
  
  .info-message-btn, 
  .info-gift-btn, 
  .info-booking-btn {
    width: 100%;
  }
}
.text-second {
  color: #c91c3b;
}
.underline {
  text-decoration: underline;
}