.page-1 {
    @apply 
    h-screen
    px-6
    bg-gradient-to-b from-[#bd193b] to-white
    snap-start overflow-x-hidden;
}
.page-1-hero {
    @apply col-span-2 h-screen flex flex-col items-center justify-center;
    font-size: .75em;
}
.page-1-title{
    @apply text-xl text-white font-bold uppercase;
}
.page-1-subtitle {
    @apply text-lg text-white text-center;
}
.page-1-button-text {
    @apply text-center;
    font-size: .75em;
}
#en-savoir-plus {
    @apply gap-4;
}
.page-3-text-container {
    @apply h-1/2 -mt-12 px-4 flex flex-col;
}
/* md */
@media (width >= 48rem) {
    .page-1 {
        @apply grid grid-cols-3;
    }
    .page-1-hero {
        @apply h-auto;
        font-size: 1em;
    }
    .page-1-title {
        @apply text-4xl;
    }
    .page-1-subtitle {
        @apply text-2xl;
    }
    #en-savoir-plus {
        @apply gap-0;
    }
    .page-3-text-container {
        @apply mt-4 px-0 gap-2;
    }
}
/* lg */
@media (width >= 64rem) {
    
}
/* xl */
@media (width >= 80rem) {
    
}