/* src/pages/tarifs/Tarifs.css */
.tarifs-header {
  text-align: center;
  padding-top: 50px;
  /* Augmentation du padding supérieur */
  padding-bottom: 50px;
  /* Augmentation du padding inférieur */
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tarifs-header h1 {
  font-size: 3.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 2rem;
  /* Augmentation de la marge inférieure du titre */
  text-align: center;
}

.tarifs-header p {
  font-size: 1.5rem;
  color: #666;
  text-align: center;
  max-width: 800px;
  /* Pour s'assurer que le texte reste à une largeur raisonnable */
  margin: 0 auto;
  /* Centrage horizontal */
}

.bande-section {
  position: relative;
  height: 400px;
  width: 100%;
  margin-top: 0;
  overflow: hidden;
}

.bande-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bande-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.2));
}

.bande-content {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
}

.bande-content h1 {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.accent-dot {
  color: #bd193b;
}

.bande-content p {
  font-size: 1.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.tarifs-section {
  padding: 5rem 0;
  background-color: #f8f9fa;
}


.tarifs-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  max-width: 2000px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (max-width: 768px) {
  .tarifs-header {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .bande-section {
    height: 300px;
    margin-top: 70px;
  }

  .bande-content h1 {
    font-size: 2.5rem;
  }

  .bande-content p {
    font-size: 1.25rem;
  }

  .tarifs-cards-container {
    flex-direction: column;
    align-items: center;
  }
}

/* Ajoutez ce code à la fin de votre fichier Abonnements.css */

.trial-section {
  position: relative;
  padding: 6rem 0;
  background-color: #fff;
  overflow: hidden;
}

.trial-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 0 1rem;
}

.trial-content h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1.5rem;
}

.trial-content p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}

.trial-button {
  display: inline-block;
  text-decoration: none;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #bd193b;
  color: white;
  padding: 1rem 2rem;
  border-radius: 30px;
  font-weight: 600;
  text-transform: uppercase;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.trial-button:hover {
  transform: translateY(-2px);
  background-color: #a01532;
}

.trial-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.beauty-icon {
  position: absolute;
  opacity: 0.1;
  fill: #bd193b;
}

.icon-1 {
  width: 60px;
  height: 60px;
  animation: float 6s ease-in-out infinite;
}

.icon-2 {
  width: 40px;
  height: 40px;
  animation: float 8s ease-in-out infinite;
}

.icon-3 {
  width: 50px;
  height: 50px;
  animation: float 7s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0) rotate(0);
  }

  50% {
    transform: translateY(-20px) rotate(10deg);
  }

  100% {
    transform: translateY(0) rotate(0);
  }
}

@media (max-width: 768px) {
  .trial-section {
    padding: 4rem 0;
  }

  .trial-content h2 {
    font-size: 2rem;
  }

  .trial-content p {
    font-size: 1rem;
  }
}