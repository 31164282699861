.leads .my-pagination {
    display: flex;
    align-items: center;
    gap: 20px;
}

.leads .my-pagination-info {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.leads .my-pagination-limit {
    font-weight: bold;
}

.leads .my-pagination-controls {
    display: flex;
    align-items: center;
    gap: 15px;
}

.leads .my-pagination-btn {
    background: none;
    border: none;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leads .my-pagination-btn svg {
    width: 18px;
    height: 18px;
    color: #555;
}

.leads .my-pagination-btn:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.leads .my-pagination-current {
    font-weight: bold;
    font-size: 16px;
}