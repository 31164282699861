.leads .action-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.leads .l-btn {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;
}

.leads .l-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.leads .btn-attribuer {
    background-color: #4CAF50;
    color: white;
}

.leads .btn-desattribuer {
    background-color: #333;
    color: white;
}

.leads .btn-changer-statut {
    background-color: #555;
    color: white;
}

.leads .btn-supprimer {
    background-color: #FF5722;
    color: white;
}

.leads .btn-supprimer-commentaires {
    background-color: #F44336;
    color: white;
}

.leads .create-import-buttons {
    margin-left: 20px;
    display: flex;
    gap: 10px;
}

.leads .btn-creer {
    background-color: #dc1f4f;
    color: white;
    border-radius: 30px;
}

.leads .btn-importer {
    background-color: #dc1f4f;
    color: white;
    border-radius: 30px;
}